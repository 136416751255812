import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { apiGet } from '../../api/Api'

import Loading from './../../components/Loading'
import back from './../../icons/back.svg';

import './Siswa.scss';

const Siswa = ({ user, daftarAngkatan }) => {
    const params = useParams();
    const navigation = useNavigate();

    const [loading, setLoading] = useState(false);
    const [nama, setNama] = useState('');
    const [angkatan, setAngkatan] = useState(params.angkatan != 'SEMUA' ? params.angkatan : '');
    const [data, setData] = useState([]);

    const navigate = (to) => {
        setTimeout(() => {
            navigation(to)
        }, 150)
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            let q = `id_marketing=${user.id_pegawai}`;

            if (angkatan && angkatan != 'SEMUA') {
                q += '&angkatan=' + angkatan
            }
            if (nama) {
                q += '&nama=' + nama
            }
            if (params.status) {
                q += '&status=' + params.status
            }

            console.log(q)

            apiGet(`/v1/marketing/siswa/get?${q}`)
                .then((r) => {
                    setData(r.data.data)
                    setLoading(false)
                })
        }, 500)
    }, [nama, angkatan])

    function handleChange(e) {
        if (e.target.name == 'angkatan') {
            setAngkatan(e.target.value)
        }
    }

    function filter(e) {
        e.preventDefault()

        setNama(e.target.elements.nama.value)
        setAngkatan(e.target.elements.angkatan.value)
    }

    return (
        <div className="Siswa">

            <div className="navbar">
                <span onClick={() => {
                    window.history.back(-1);
                }}>
                    <img src={back} height="25" />
                </span>

                <h1 id='nav-title'>Data siswa {params.status != 'semua' && params.status}</h1>
            </div>

            <div className="card">
                <p style={{ margin: '0 0 15px 0' }}>Filter data</p>
                <form onSubmit={filter} autoComplete={'off'}>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridColumnGap: '15px' }}>
                        <div className="form-group">
                            <input type="text" placeholder="Cari siswa..." name="nama" />
                        </div>
                        <div className="form-group">
                            <select name="angkatan" value={angkatan} onChange={handleChange}>
                                <option value="SEMUA">Semua angkatan</option>
                                {
                                    daftarAngkatan.map((item, index) => (
                                        <option value={item} key={index} >{item}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <button type="submit" style={{ width: '100%' }}>FILTER</button>
                </form>
            </div>

            <div className="card">
                {
                    loading ? <Loading />
                        : (
                            <ul>
                                {
                                    data.map((item, index) => (
                                        <li key={index}>
                                            <div>
                                                <div onClick={() => {
                                                    navigate(`/siswa/detail/${item.id}`)

                                                }}>
                                                    <p>
                                                        <b>{item.nama_lengkap.toUpperCase()}</b>
                                                    </p>
                                                    <small>{item.no_registrasi}</small>

                                                    <div className="info">
                                                        {item.kampus} / {item.kode_jurusan} / {item.angkatan_2}
                                                    </div>
                                                </div>

                                                <div className="domain">
                                                    <span className="material-symbols-outlined">
                                                        language
                                                    </span>
                                                    {
                                                        item.domain
                                                    }
                                                </div>

                                                <div className="wa">
                                                    <a href={`https://wa.me/${item.no_hp_siswa}`} target="_blank">
                                                        {item.no_hp_siswa}
                                                    </a>
                                                    {
                                                        item.id_marketing != user.id_pegawai && (
                                                            <span class="material-symbols-outlined">
                                                                supervisor_account
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div>
                                                {item.hasil_seleksi == 'DITERIMA' && <small>Diterima</small>}
                                                <span>{item.created_at}</span>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        )
                }

                {
                    !loading && data.length <= 0 && (
                        <p style={{ textAlign: 'center', padding: '50px 0' }}>Tidak ada data</p>
                    )
                }
            </div>
        </div>
    )
}

export default Siswa;