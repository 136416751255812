import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";

import { apiGet, apiPost } from '../../api/Api'

import './Login.scss'

const Login = () => {
    const navigation = useNavigate();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        apiGet('/v1/marketing/user/get').then((r) => {
            setUsers(r.data)
        });
    }, [])

    function login(e) {
        e.preventDefault();

        const param = {
            username: e.target.elements.username.value,
            password: e.target.elements.password.value,
        }

        setLoading(true);
        apiPost('/v1/marketing/user/loginUsername', param)
            .then((r) => {
                setLoading(false);

                if (r.valid) {
                    localStorage.setItem('is_login', 1)
                    localStorage.setItem('user', JSON.stringify(r.data))

                    window.location.href = '/'
                } else {
                    alert(r.message)
                }
            })
    }

    return (
        <div className="Login">
            <form onSubmit={login} autoComplete='off'>
                <p style={{ textAlign: 'center', marginBottom: '50px' }}>
                    <img src={'https://pra.pspp-integrated.com/assets/img/logo.png'} width="150" />
                </p>

                <div className="form-group">
                    <label>Username</label>
                    <input type="text" name="username" className="form-control" />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input type="password" name="password" className="form-control" />
                </div>

                <button type="submit" disabled={loading}>LOGIN</button>
            </form>
        </div>
    )
}

export default Login;