import back from './../../icons/back.svg';
import './Profil.scss'

const Profil = () => {

    const profil = JSON.parse(localStorage.getItem('user'))

    return (
        <div className="Profil">
            <div className="navbar">
                <span onClick={() => {
                    window.history.back(-1);
                }}>
                    <img src={back} height="25" />
                </span>

                <h1 id='nav-title'>Profil</h1>
            </div>

            <div className="card">
                <form>
                    <div className="form-group">
                        <label>Nama</label>
                        <input type="text" className="form-control" defaultValue={profil.nama}/>
                    </div>
                    <div className="form-group">
                        <label>No HP</label>
                        <input type="text" className="form-control"  defaultValue={profil.no_hp}/>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control"  defaultValue={profil.email}/>
                    </div>
                    <div className="form-group">
                        <label>Link Marketing</label>
                        <input type="text" className="form-control" />

                        <span>Copy</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Profil;