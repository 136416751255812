import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { apiGet } from './../../api/Api'

import back from './../../icons/back.svg';
import './Siswa.scss';

const SiswaDetail = () => {
    const { id } = useParams();

    const [data, setData] = useState({});
    const [tabActive, setTabActive] = useState('jurusan');

    useEffect(() => {
        apiGet(`/v1/marketing/siswa/get?id=${id}`)
            .then((r) => {
                setData(r.data.data[0]);
            })
    }, [])

    return (
        <div className="Siswa">

            <div className="navbar">
                <span onClick={() => {
                    window.history.back(-1);
                }}>
                    <img src={back} height="25" />
                </span>

                <h1 id='nav-title'>{data.nama_lengkap?.toUpperCase()}</h1>
            </div>

            <div className="card">
                <ul className="tab">
                    <li
                        className={tabActive == 'jurusan' ? 'active' : ''}
                        onClick={() => {
                            setTabActive('jurusan')
                        }}
                    >
                        Jurusan & Kampus
                    </li>
                    <li
                        className={tabActive == 'data-diri' ? 'active' : ''}
                        onClick={() => {
                            setTabActive('data-diri')
                        }}
                    >
                        Data Diri
                    </li>
                    <li
                        className={tabActive == 'pendidikan' ? 'active' : ''}
                        onClick={() => {
                            setTabActive('pendidikan')
                        }}
                    >
                        Pendidikan
                    </li>
                    <li
                        className={tabActive == 'kontak' ? 'active' : ''}
                        onClick={() => {
                            setTabActive('kontak')
                        }}
                    >
                        Kontak
                    </li>
                    <li
                        className={tabActive == 'berkas' ? 'active' : ''}
                        onClick={() => {
                            setTabActive('berkas')
                        }}>Berkas</li>
                    <li
                        className={tabActive == 'pengumuman' ? 'active' : ''}
                        onClick={() => {
                            setTabActive('pengumuman')
                        }}>Pengumuman</li>
                </ul>

                {
                    tabActive == 'jurusan' && (
                        <div id="jurusan-kampus">
                            <h3>Jurusan & Kampus</h3>

                            <form>
                                <div className="form-group">
                                    <label>Jurusan</label>
                                    <input type="text" className="form-control" defaultValue={data.jurusan} />
                                </div>
                                <div className="form-group">
                                    <label>Angkatan</label>
                                    <input type="text" className="form-control" defaultValue={data.angkatan_2} />
                                </div>
                                <div className="form-group">
                                    <label>Kampus</label>
                                    <input type="text" className="form-control" defaultValue={data.kampus} />
                                </div>
                            </form>
                        </div>
                    )
                }
                {
                    tabActive == 'data-diri' && (
                        <div id="data-diri">
                            <h3>Data Diri</h3>

                            <form>
                                <div className="form-group">
                                    <label>Nama lengkap</label>
                                    <input type="text" className="form-control" defaultValue={data.nama_lengkap?.toUpperCase()} />
                                </div>
                                <div className="form-group">
                                    <label>Alias</label>
                                    <input type="text" className="form-control" defaultValue={data.nama_panggilan?.toUpperCase()} />
                                </div>
                                <div className="form-group">
                                    <label>Agama</label>
                                    <input type="text" className="form-control" defaultValue={data.agama} />
                                </div>
                                <div className="form-group">
                                    <label>Suku</label>
                                    <input type="text" className="form-control" defaultValue={data.suku} />
                                </div>
                                <div className="form-group">
                                    <label>Jenis Kelamin</label>
                                    <input type="text" className="form-control" defaultValue={data.jenis_kelamin} />
                                </div>
                                <div className="form-group">
                                    <label>Berjilbab</label>
                                    <input type="text" className="form-control" defaultValue={data.jilbab} />
                                </div>
                                <div className="form-group">
                                    <label>Tempat lahir</label>
                                    <input type="text" className="form-control" defaultValue={data.tempat_lahir} />
                                </div>
                                <div className="form-group">
                                    <label>Tgl lahir</label>
                                    <input type="text" className="form-control" defaultValue={data.tgl_lahir} />
                                </div>
                                <div className="form-group">
                                    <label>Usia</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Tinggi badan (cm)</label>
                                    <input type="text" className="form-control" defaultValue={data.tinggi_badan} />
                                </div>
                                <div className="form-group">
                                    <label>Berat badan (kg)</label>
                                    <input type="text" className="form-control" defaultValue={data.berat_badan} />
                                </div>
                                <div className="form-group">
                                    <label>Hobi</label>
                                    <input type="text" className="form-control" defaultValue={data.hobi} />
                                </div>
                                <div className="form-group">
                                    <label>Gol darah</label>
                                    <input type="text" className="form-control" defaultValue={data.golongan_darah} />
                                </div>
                                <div className="form-group">
                                    <label>Ukuran sepatu</label>
                                    <input type="text" className="form-control" defaultValue={data.ukuran_sepatu} />
                                </div>
                                <div className="form-group">
                                    <label>Ukuran baju</label>
                                    <input type="text" className="form-control" defaultValue={data.ukuran_baju} />
                                </div>
                                <div className="form-group">
                                    <label>Motivasi masuk PSPP</label>
                                    <textarea defaultValue={data.motivasi} rows={3} style={{ width: 'calc(100% - 32px)' }}></textarea>
                                </div>
                            </form>
                        </div>
                    )
                }

                {
                    tabActive == 'pendidikan' && (
                        <div id="pendidikan">
                            <h3>Pendidikan</h3>

                            <form>
                                <div className="form-group">
                                    <label>Provinsi pendidikan</label>
                                    <input type="text" className="form-control" defaultValue={data.provinsi_pendidikan} />
                                </div>
                                <div className="form-group">
                                    <label>Kota pendidikan</label>
                                    <input type="text" className="form-control" defaultValue={data.kabupaten_pendidikan} />
                                </div>
                                <div className="form-group">
                                    <label>SMA</label>
                                    <input type="text" className="form-control" defaultValue={data.sekolah} />
                                </div>
                                <div className="form-group">
                                    <label>Dari tahun</label>
                                    <input type="text" className="form-control" defaultValue={data.tahun_awal_sma} />
                                </div>
                                <div className="form-group">
                                    <label>Sampai tahun</label>
                                    <input type="text" className="form-control" defaultValue={data.tahun_akhir_sma} />
                                </div>
                                <div className="form-group">
                                    <label>SMP</label>
                                    <input type="text" className="form-control" defaultValue={data.nama_smp} />
                                </div>
                                <div className="form-group">
                                    <label>Dari tahun</label>
                                    <input type="text" className="form-control" defaultValue={data.tahun_awal_smp} />
                                </div>
                                <div className="form-group">
                                    <label>Sampai tahun</label>
                                    <input type="text" className="form-control" defaultValue={data.tahun_akhir_sma} />
                                </div>
                                <div className="form-group">
                                    <label>SD</label>
                                    <input type="text" className="form-control" defaultValue={data.nama_sd} />
                                </div>
                                <div className="form-group">
                                    <label>Dari tahun</label>
                                    <input type="text" className="form-control" defaultValue={data.tahun_awal_sd} />
                                </div>
                                <div className="form-group">
                                    <label>Sampai tahun</label>
                                    <input type="text" className="form-control" defaultValue={data.tahun_akhir_sd} />
                                </div>
                                <div className="form-group">
                                    <label>Kursus yang pernah diikuti</label>
                                    <textarea defaultValue={data.kursus} rows="3" style={{ width: 'calc(100% - 32px)' }}></textarea>
                                </div>
                            </form>
                        </div>
                    )
                }

                {
                    tabActive == 'kontak' && (
                        <div id="kontak">
                            <h3>Kontak</h3>

                            <form>
                                <div className="form-group">
                                    <label>Alamat lengkap</label>
                                    <input type="text" className="form-control" defaultValue={data.alamat_siswa} />
                                </div>
                                <div className="form-group">
                                    <label>RT</label>
                                    <input type="text" className="form-control" defaultValue={data.rt} />
                                </div>
                                <div className="form-group">
                                    <label>RW</label>
                                    <input type="text" className="form-control" defaultValue={data.rw} />
                                </div>
                                <div className="form-group">
                                    <label>Provinsi</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Kabupaten</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Kecamatan</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Kelurahan</label>
                                    <input type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input type="text" className="form-control" defaultValue={data.email} />
                                </div>
                                <div className="form-group">
                                    <label>No HP</label>
                                    <input type="text" className="form-control" defaultValue={data.no_hp_siswa} />
                                </div>
                                <div className="form-group">
                                    <label>Nama orangtua</label>
                                    <input type="text" className="form-control" defaultValue={data.nama_orangtua} />
                                </div>
                                <div className="form-group">
                                    <label>No HP orangtua</label>
                                    <input type="text" className="form-control" defaultValue={data.no_hp_orangtua} />
                                </div>
                                <div className="form-group">
                                    <label>Pekerjaan orangtua</label>
                                    <input type="text" className="form-control" defaultValue={data.pekerjaan_orangtua} />
                                </div>
                                <div className="form-group">
                                    <label>Alamat orangtua</label>
                                    <input type="text" className="form-control" defaultValue={data.alamat_orangtua} />
                                </div>
                            </form>
                        </div>
                    )
                }

                {
                    tabActive == 'berkas' && (
                        <div id="berkas">
                            <h3>Berkas</h3>

                            <form>
                                <div className="form-group" style={{marginBottom: '30px'}}>
                                    <b>Foto full badan</b>
                                    {
                                        data.berkas_foto ? (
                                            <p style={{ textAlign: 'center', border: '1px solid #ddd', background: '#000' }}>
                                                <img src={data.berkas_foto} width="50%" />
                                            </p>
                                        ) : (
                                            <p style={{textAlign: 'center', fontStyle: 'italic'}}>Belum diupload</p>
                                        )
                                    }
                                </div>
                                <div className="form-group" style={{marginBottom: '30px'}}>
                                    <b>Surat keterangan sehat</b>
                                    {
                                        data.berkas_surat_sehat ? (
                                            <p style={{ textAlign: 'center', border: '1px solid #ddd', background: '#000' }}>
                                                <img src={data.berkas_surat_sehat} width="50%" />
                                            </p>
                                        ) : (
                                            <p style={{textAlign: 'center', fontStyle: 'italic'}}>Belum diupload</p>
                                        )
                                    }
                                </div>
                                <div className="form-group" style={{marginBottom: '30px'}}>
                                    <b>Identitas</b>
                                    {
                                        data.berkas_identitas ? (
                                            <p style={{ textAlign: 'center', border: '1px solid #ddd', background: '#000' }}>
                                                <img src={data.berkas_identitas} width="50%" />
                                            </p>
                                        ) : (
                                            <p style={{textAlign: 'center', fontStyle: 'italic'}}>Belum diupload</p>
                                        )
                                    }
                                </div>
                                <div className="form-group" style={{marginBottom: '30px'}}>
                                    <b>Kartu pelajar / sampul rapor</b>
                                    {
                                        data.berkas_rapor ? (
                                            <p style={{ textAlign: 'center', border: '1px solid #ddd', background: '#000' }}>
                                                <img src={data.berkas_rapor} width="50%" />
                                            </p>
                                        ) : (
                                            <p style={{textAlign: 'center', fontStyle: 'italic'}}>Belum diupload</p>
                                        )
                                    }
                                </div>
                                <div className="form-group" style={{marginBottom: '30px'}}>
                                    <b>Bukti biaya pendaftaran</b>
                                    {
                                        data.berkas_bukti_biaya_pendaftaran ? (
                                            <p style={{ textAlign: 'center', border: '1px solid #ddd', background: '#000' }}>
                                                <img src={data.berkas_bukti_biaya_pendaftaran} width="50%" />
                                            </p>
                                        ) : (
                                            <p style={{textAlign: 'center', fontStyle: 'italic'}}>Belum diupload</p>
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                    )
                }

                {
                    tabActive == 'pengumuman' && (
                        <div id="pengumuman">
                            <h3>Pengumuman</h3>
                            <p style={{ fontWeight: 300, marginTop: '30px' }}>Hasil seleksi:</p>
                            {
                                data.hasil_seleksi == 'DITERIMA' && (
                                    <p>
                                        <h1>Diterima</h1>
                                        <iframe src={data.surat_hasil_seleksi} style={{ width: '100%', height: '600px', border: 0 }} />
                                    </p>
                                )
                            }
                            {
                                data.hasil_seleksi == 'TIDAK DITERIMA' && (
                                    <p style={{fontWeight: 300}}>
                                        <h1>Tidak Diterima</h1>
                                        <br />                                        
                                        { data.catatan }
                                    </p>
                                )
                            }
                        </div>
                    )
                }
            </div>

            <div className="card">
                <h2 style={{fontWeight: 'normal'}}>No Registrasi</h2>
                <p>{ data.id }</p>
                <h2 style={{fontWeight: 'normal'}}>Password</h2>
                <p>{ data.password }</p>
            </div>
        </div >
    )
}

export default SiswaDetail;